import React, { useState, useEffect, useContext } from "react";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import ZodiacDD from "../../../components/ZodiacDD";
import banner from "../../../staticAssets/Horoscope Bg.png";
import logo from "../../../staticAssets/Virgo.png";
import axios from "axios";
import { navigate } from "gatsby";
import { AuthContext } from "../../../context/Auth";
import { Helmet } from "react-helmet-async";
import { AnalyticsContext } from "../../../context/Analytics";

function Index({location}) {
  const [blogs, setblogs] = useState([]);
const { gtmPageViewEvent } = useContext(AnalyticsContext) || {
    gtmPageViewEvent: () => {},
  };
  const { user } = useContext(AuthContext) || {
    user: {},
  };
  useEffect(() => {
    const fn = async () => {
      let res = await axios.get(
        "https://www.bodhi.app/blog/wp-json/wp/v2/posts"
      );

      if (res.status == 200) {
        setblogs([...res.data]);
      }
    };
    gtmPageViewEvent(window.location.pathname, window.location.host);
fn();
  }, []);
  return (
    <div className="h-screen flex flex-col block hide-scroll-bar scrollbar-hide worksans w-screen overflow-x-clip">
      <Header />
      <Helmet>
        <link
          rel="canonical"
          href={"https://www.bodhi.app"+location?.pathname}
        />
      </Helmet>
      <div className="flex-1 overflow-y-auto flex flex-col">
        <main className="grow hide-scroll-bar scrollbar-hide">
          <div className=" w-full relative bg-[#FFEEEF] overflow-hidden">
            <img className="h-32 md:h-auto w-full object-fill" src={banner} />
            <div className="absolute top-0 w-full h-full flex justify-center items-center">
              <h1 className="text-center text-2xl md:text-4xl font-semibold text-white">
                Virgo Traits
              </h1>
            </div>
          </div>
          <div className="container mx-auto flex flex-col">
            <div className="flex flex-wrap my-2 ml-2">
              <a
                href="https://www.bodhi.app/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Bodhi</h4>
              </a>
              
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Zodiac Signs</h4>
              </a>
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/virgo"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Virgo</h4>
              </a>
              <h4 className="mx-2 text-sm">/</h4>
              <h4 className="text-sm">Virgo Traits</h4>
              
              
             
            </div>
            <div className="flex mt-10 items-center">
              <div>
                <img className="h-24 md:h-48" src={logo} />
              </div>
              <div className="flex-1 flex flex-col md:flex-row">
                <div className=" flex-1 flex flex-col">

                <h1 className="font-semibold text-xl md:text-4xl ml-4">Virgo Traits </h1>
                <h2 className="text-sm md:text-base ml-4">July 23 - August 22</h2>
                </div>
                <div className="">
                <ZodiacDD name={"Change Sunsign"} />
              </div>
              </div>
              
            </div>
            <div className="grid grid-cols-3 md:grid-cols-4 gap-2 md:gap-8 mt-10 text-[0.8rem] md:text-base mx-1">
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2 py-1" onClick={() => {
                    navigate("/zodiac-signs/virgo/virgo-woman");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Virgo Woman</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>

              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/virgo/virgo-health");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Virgo Health</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/virgo/virgo-career");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Virgo Career</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/virgo/virgo-love");
                  }}>
                  <h5 className="font-semibold text-black">Virgo Love</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/virgo/virgo-nature");
                  }}>
                  <h5 className="font-semibold text-black">Virgo Nature</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/virgo/virgo-man");
                  }}>
                  <h5 className="font-semibold text-black">Virgo Man</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/virgo/virgo-relationship");
                  }}>
                  <h5 className="font-semibold text-black">Virgo Relationship</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/virgo/virgo-traits");
                  }}>
                  <h5 className="font-semibold text-black">Virgo Traits</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/virgo/virgo-facts");
                  }}>
                  <h5 className="font-semibold text-black">Virgo Facts</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
            </div>
            <span className="my-4 mx-1 md:mx-0">
            <p className="playfair text-black text-xl md:text-3xl">Personality traits</p><br/>
                  The human mind is a complex and mysterious place. But as
                  tricky as the reason is, it's possible to understand it
                  through various lenses, each of which sheds light on different
                  aspects of the mind. <br /> <br />
                  These different lenses are primarily used to study the same
                  thing, but sometimes they're used to explore other things. One
                  of these different lenses is the lens of personality.
                  <br /> <br />
                  <p className="playfair text-black text-xl md:text-3xl">
                    What do you understand by personality traits?
                  </p>
                  <br/>
                  Personality is how people are, the way they act and behave. It
                  has been shown that personality can be changed and developed
                  through experience, but your genes also influence it. In that
                  way, we will look at the main traits of Virgo that make up a
                  person's personality and what they mean for you. We'll also
                  look at how you can use this information when working with
                  others and develop your unique personality to set you apart
                  from the crowd. <br />
                  <br />
                  <p className="playfair text-black text-xl md:text-3xl">Positive/Negative Traits</p><br/>
                  Virgo is one of the zodiac signs known for being perfect and
                  being hard on themselves. Virgo individuals take their flaws
                  and mistakes very seriously. They are always striving to be
                  better, and they are their harshest critics. However, even the
                  most perfect human being has flaws and makes mistakes.
                  <br />
                  <br />
                  They are known for being efficient, which means they are often
                  accomplished in their goals. However, not all Virgos possess
                  these qualities. Some may not be as dedicated or hardworking
                  as others, and some may not be as efficient.
                  <br />
                  <br />
                  You are known for being hardworking and organized. you are
                  also known to be worry a lot. These are all true, but not all
                  Virgos are the same. Some Virgos are laid-back and don't care
                  about being perfect or making sure everything is in place.
                  <br />
                  <br />
                  <p className="font-bold inline-block text-orange-500">Look at some qualities:</p>
                  <br />
                  ● Virgos are the most humble of all zodiac signs. <br />
                  ● They are astute, analytical, and ideal. <br />
                  ● They are hardworking, reliable, and have a strong sense of
                  duty and loyalty.
                  <br />
                  ● They are also modest and tend to beat themselves up when
                  they make mistakes.
                  <br />
                  ● Virgos are perfectionists. <br />
                  ● They tend to be hardworking, responsible, and organized.{" "}
                  <br />
                  ● They are also known for being critical and sometimes
                  quick-tempered. They can also be indecisive at times, which
                  causes them to overthink.
                  <br />
                  <br />
                  Virgo, the sixth sign of the zodiac, is known for being
                  hardworking and analytical. People who have Virgo astrology in
                  their natal charts have a strong sense of integrity and a
                  desire for order and productivity. They are also known for
                  having a strong sense of empathy and compassion for others.{" "}
                  <br />
                  <br />
                  You virgos are associated with traits such as responsibility,
                  perseverance, and discernment. Known for your high standards
                  and tendency to overthink, which can cause them to be hard on
                  themselves when they don't live up to their expectations. When
                  they do live up to their high standards, though, Virgos can be
                  hardworking and productive. While some people see the word
                  "workaholic" and think it's a negative thing, workaholics
                  often have a strong work ethic and are passionate about their
                  work; Virgos are often like this.
                  <br />
                  <br />
                  <p className="font-bold inline-block text-orange-500">Loyal:</p> Shy and introverted, making them hard to get to know.
                  But once they get to know you, they'll be the most loyal and
                  dedicated friends you ever had.
                  <br />
                  <br />
                  <p className="font-bold inline-block text-orange-500">Honesty:</p> They have strong convictions, and they don't like to
                  sugarcoat things. They're blunt and not afraid to say what
                  they think. It can cause them to be insensitive, but they only
                  mean communicating their feelings.
                  <br />
                  <br />
                  Virgo's honesty is refreshing. They are not the type to mince
                  words or hold back on criticism. People who spoke direct and
                  to the point, with no room for empty pleasantries or small
                  talk. <br />
                  <br />
                  Negative aspects of Virgo's personality test Like all signs,
                  Virgo has its share of negative personality traits. Some
                  people may think that Virgos are unfriendly and standoffish.
                  <br />
                  <br />
                  Virgo, the sixth sign of the zodiac, has a strong sense of
                  duty and can be a little bit too critical at times. People can
                  be indecisive and may find it hard to express their emotions.
                  <br />
                  <br />
                  <p className="font-bold inline-block text-orange-500">Overthinking:</p> Virgo is a complicated zodiac sign with a lot of
                  positive traits. However, one of the negative traits of Virgo
                  is that they tend to overthink things. They can become so
                  caught up in analyzing the most trivial things that they lose
                  the ability to enjoy the moment. <br />
                  <br />
                  It is a trait that Virgo should work to try and overcome. It's
                  constantly worrying about things, making sure everything is in
                  its place, and finding the best possible solution to a
                  problem.
                  <br />
                  <br />
                  <p className="font-bold inline-block text-orange-500">Image conscious:</p> Virgo, the Virgin, is the astrological sign
                  representing the mind, the intellect, and the complex web of
                  thoughts and emotions that make up the human psyche. The Virgo
                  mind is analytical, intuitive, and complex. It is a critical
                  mind, constantly analyzing, weighing, and evaluating. It is a
                  restless mind, never satisfied with the status quo,
                  continually seeking ways to improve and grow.
                  <br />
                  <br />
                  <p className="font-bold inline-block text-orange-500">Insecurity:</p> Virgo, the Virgin, is known for being a bit of a
                  Negative Nelly. It isn't always obvious, though. <br />
                  <br />
                  Virgo is a Fixed sign, which means that it has little or no
                  sense of direction. It can make them seem directionless and
                  insecure, making them misunderstood and undervalued. <br />
                  <br />
                  However, Virgo isn't naturally good at being assertive doesn't
                  mean that they can't be bold and powerful. They need to learn
                  how to do it to feel comfortable with it.
                  <br />
                  <br />
            </span>
          </div>
          <div className="container mx-auto">
            <div className="flex justify-between mb-4 mx-2">
              <h1 className="text-xl md:text-3xl text-black playfair">
                Recent Blogs
              </h1>
              <a
                className="text-sm md:text-md text-orange-500 text-center font-semibold"
                href="https://www.bodhi.app/blog/"
              >
                See More
              </a>
            </div>
            <div className="relative">
                <div className="absolute top-0 right-0 w-0 md:w-36 fixed h-full bg-gradient-to-r from-transparent to-white"></div>
              <div className="flex overflow-x-scroll pb-4 mt-2 hide-scroll-bar scrollbar-hide">
                <div className="flex flex-nowrap ml-2">
                  {blogs.slice(0, 10).map((item, i) => {
                    return (
                      <a href={item.link} key={i} target="_blank">
                        <div className="flex flex-col h-80 w-52 justify-start items-start bg-gray-100 mr-10 mb-10 rounded-lg overflow-hidden hover:shadow-lg">
                          <img
                            className="object-cover w-52 h-52 w-auto bg-gray-100"
                            src={
                              item?.yoast_head_json?.og_image
                                ? item?.yoast_head_json?.og_image[0]?.url
                                : ""
                            }
                          />
                          <span className="mt-2 block mx-2 text-center">
                            {item?.yoast_head_json?.title ?? ""}
                          </span>
                        </div>
                      </a>
                    );
                  })}
                  {/* <div className="flex h-80 w-52 justify-center items-center "></div> */}
                </div>
              </div>
            </div>
          <button
            className="justify-center text-white fixed right-2 bottom-2 rounded-t-2xl but px-4 py-2 flex gap-1 shadow-xl"
            onClick={() => {
              navigate("/consultAstro");
            }}
          >
            {user.name !== undefined?`Consult Top Astrologers Now!`:`Login & get ${
              user?.currencyType == "usd" ? "$ 4" : "₹ 91"
            } in your account!`}
          </button>
          </div>
        </main>
        <Footer />
      </div>
    </div>
  );
}

export default Index;
